<template>
  <v-container>
    <v-row class="mx-2">
      <v-btn @click.native="dialog = true" class="primary">Add</v-btn>
      <v-col v-if="record" cols="12" sm="12" md="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="Headers"
          :items="Branches"
          :search="search"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1"
          :server-search="true"
        >
          <template v-slot:item.created_at="{ item }">{{
            item.created_at | moment("LLL")
          }}</template>
          <template v-slot:item.Active="{ item }">
            {{ item.Active ? "Active" : "In-Active" }}
          </template>

          <template v-slot:item.id="props">
            <v-btn
              @click="removeItem(props.item.id, '/assign_branches')"
              color="white"
              plain
              small
            >
              <v-icon color="red">mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <!-- one item selection modal -->
      <v-dialog v-model="dialog" width="950">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Branch Assignment</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :items="OBPLS"
                    search-input="true"
                    item-value="BPLId"
                    :item-text="(item) => item.BPLId + '  -  ' + item.BPLName"
                    outlined
                    multiple
                    search="search"
                    v-model="EmpBranch.BPLId"
                    label="Branches"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-btn color="accent" @click="AssignBranch" :loading="loader">
                    <v-icon left>mdi-content-save</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of item modal -->
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    record: {},
    Branches: [],
    loader: false,
    OBPLS: [],
    EmpBranch: {},
    Payment: {},
    search: "",
    searchWhs: null,
    dialog: false,
    Headers: [
      { text: "Branch ID", value: "BPLId" },
      { text: "Branch Name", value: "branch.BPLName" },
      { text: "Active", value: "Active" },
      { text: "Created On", value: "created_at" },
      { text: "Actions", value: "id" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),

  watch: {
    options: {
      handler() {
        this.BranchesCollection();
      },
      deep: true,
    },
  },
  methods: {
    BranchesCollection() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch(
          "get",
          `/employee/${this.$route.params.empID}/branchesCollection?page=${this.options.page}&per_page=${this.options.itemsPerPage}`
        )
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.Branches = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          self.loading = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },

    AssignBranch() {
      let data = this.EmpBranch;

      console.log(data);
      const url = `/assign_branches/${this.$route.params.empID}`;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.BranchesCollection();
          } else {
            self.loading = false;
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
          this.dialog = false;
        })
        .catch((err) => {
          self.$store.commit("loader", false);
          self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
        });
    },
    getbranches() {
      this.loading = true;
      this.$store
        .dispatch("get", `/branches`)
        .then((res) => {
          let Custm = {
            BPLId: "-1",
            BPLName: "ALL",
          };
          this.OBPLS = res.ResponseData;
          this.OBPLS.unshift(Custm);

          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    removeItem(id, baseurl) {
      var userResponse = confirm(
        "Are you sure you want to delete? This action cannot be undone."
      );
      if (userResponse === true) {
        const url = `${baseurl}/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then((res) => {
            if (res.ResultCode == 1200) {
              this.BranchesCollection();
            } else {
              self.loading = false;
              this.$refs.snackbar.show(res.ResultDesc, "red");
            }
            this.Dialog = false;
            this.dialog = false;
          })
          .catch((err) => {
            self.$store.commit("loader", false);
            self.$refs.snackbar.show(err.response?.data?.ResultDesc, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
  },
  created() {
    this.getbranches();
    this.BranchesCollection();
  },
};
</script>
